@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

body, html, #root, .app {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

body, html, #root, .app, .header, .bottom-nav {
    background-color: #000;
}

.header {
  color: white;
  padding: 10px;
}

.main-nav h1 {
  margin: 10px 0 10px 10px;
  font-family: 'Pacifico', cursive; /* Use a script font */
  font-size: 1.5em; /* Increase font size */
}

.sub-nav {
    overflow-x: auto;
    white-space: nowrap;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.sub-nav::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

.sub-nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
}

.sub-nav ul li {
  display: inline-block;
  transition: background-color 0.3s ease-in-out; /* Add transition for background color */
}

.sub-nav ul li:hover {
  background-color: #555;
}

.sub-nav ul li a {
  color: #555;
  text-decoration: none;
  padding: 10px;
  display: block;
}

.sub-nav ul li:hover a {
    color: #000 !important;
}

.content {
  color: white;
  flex: 1;
  padding: 20px;
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.bottom-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
}

.bottom-nav ul li {
  margin: 0;
}

.bottom-nav ul li a {
  color: white;
  text-decoration: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.bottom-nav ul li a i {
  font-size: 20px;
  margin-bottom: 5px;
}